import 'echarts'
import 'echarts/map/js/china'
import 'echarts/map/js/world'
import 'echarts-wordcloud'

import EChart from 'vue-echarts'
import dark from './echart-theme-dark.js'
import shine from './echart-theme-shine.js'
import macarons from './echart-theme-macarons.js'

EChart.registerTheme('dark', dark)
EChart.registerTheme('shine', shine)
EChart.registerTheme('macarons', macarons)

export default EChart
