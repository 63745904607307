import axios from 'axios'

export const getPageData = function (url) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: url,
      crossDomain: true,
      cache: false
    }).then(res => {
      resolve(res.data)
    }).catch(error => {
      reject(error)
    })
  })
}
